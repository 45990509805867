import store from '@/store';


function putEnumItem(itemKey, itemValue){
    Object.assign(store.state.enums, {
        [itemKey]: itemValue
    })
}

function getEnumItem (itemKey, valueField, textField, callback, forceReplace=false){
    let enumItem = store.state.enums[itemKey];
    if(enumItem && valueField && textField){
        enumItem = enumItem.map(item => {
            return {
                [valueField]: item.value,
                [textField]: item.text,
            }
        })
    }

    if((enumItem == undefined || forceReplace) && callback){
        let timeout = setTimeout(()=>{
            clearTimeout(timeout)
            timeout = null;
            const promise = callback();
            promise.then(itemValue=>{
                putEnumItem(itemKey, itemValue)
            })
        },0)
    }
    return enumItem || [];
}

function getEnumTextByValue(itemKey, byValue) {
    const valueField = 'value', textField = 'text';
    const enumItem = getEnumItem(itemKey, valueField, textField)
    const findedItem = enumItem.find((item)=>{
        // eslint-disable-next-line no-prototype-builtins
        return item.hasOwnProperty(valueField) && item[valueField] === byValue
    })
    if (findedItem){
        return findedItem[textField]
    }
}

export {
    putEnumItem,
    getEnumItem,
    getEnumTextByValue
}
